<template>
  <div class="container">
    <div class="search">
      <el-row>
        <el-button v-if="rank == '1' &&isdealer == 'fasle'" type="primary" @click="openAddProjectDialog()">添加项目</el-button> <!-- 添加项目对话框 -->
        <el-dialog title="" :visible.sync="addDialogVisible" width="80%" @close="closeAddDialog">
          <div style=" display: flex;">
            <div style="width:50%">
              <div style="margin-bottom: 10px;">
                添加项目
              </div>
              <el-form ref="addProjectFormData" :model="addProjectFormData" label-width="100px"
                :rules="addProjectFormDataRules">
                <el-form-item label="项目名称" prop="name">
                  <el-input v-model="addProjectFormData.name"></el-input>
                </el-form-item>
                <!-- <el-form-item label="所属公司">
          <el-select v-model="addFormData.corporation" placeholder="请选择">
            <el-option
              v-for="item in enterpriseOptions"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item> -->
                <el-form-item label="项目详情" prop="details">
                  <el-input v-model="addProjectFormData.details"></el-input>
                </el-form-item>
                <el-form-item label="项目地址" prop="site">
                  <el-input v-model="addProjectFormData.site"></el-input>
                </el-form-item>

                <el-form-item label="工程性质" prop="engineering">
                  <el-input v-model="addProjectFormData.engineering"></el-input>
                </el-form-item>
                <el-form-item label="工程阶段" prop="angineering">
                  <el-select v-model="addProjectFormData.angineering" placeholder="请选择">
                    <el-option v-for="item in projectOptions" :key="item.value" :label="item.label" :value="item.value">
                    </el-option>
                  </el-select>
                  <!-- <el-input v-model="addProjectFormData.angineering"></el-input> -->
                </el-form-item>
                <el-form-item label="开工时间" prop="create_time">
                  <el-date-picker v-model="addProjectFormData.create_time" type="date" placeholder="选择日期">
                  </el-date-picker>
                </el-form-item>
                <el-form-item label="拟竣工时间" prop="update_time">
                  <el-date-picker v-model="addProjectFormData.update_time" type="date" placeholder="选择日期">
                  </el-date-picker>
                </el-form-item>
                <el-form-item label="所在地" prop="detaileds">
                  <el-cascader size="large" :options="options" v-model="addProjectFormData.detaileds" clearable />
                </el-form-item>
              </el-form>
              <span slot="footer" class="dialog-footer">
                <el-button @click="addDialogVisible = false">取 消</el-button>
                <el-button type="primary" @click="addProject">确 定</el-button>
              </span>
            </div>
            <div style="width:50%">
              <div style="margin-bottom: 10px;">
                项目审核
              </div>
              <el-table :data="tableData1" border style="width: 100%" height="550">
                <el-table-column prop="name" label="工程名称" width="180">
                </el-table-column>
                <el-table-column prop="angineering" label="工程阶段" width="180">
                </el-table-column>
                <el-table-column prop="engineering" label="工程性质">
                </el-table-column>
                <el-table-column prop="details" label="工程简介" show-overflow-tooltip="true">
                </el-table-column>
                <el-table-column prop="create_time" label="提交时间">
                </el-table-column>
                <el-table-column prop="Audit" label="审核状态">
                </el-table-column>
              </el-table>
            </div>
          </div>
        </el-dialog>
        <!-- <el-col :span="6">
          <span>项目名称 : </span>
          <el-input v-model="name" placeholder="请输入内容"></el-input>
        </el-col> -->
        <!-- <el-col :span="6">
          <span>所属公司 : </span>
          <el-select v-model="corporation" placeholder="请选择" @change="getProject">
            <el-option
              v-for="item in enterpriseOptions"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-col> -->
        <!-- <el-col :span="6">
          <span>项目名称 : </span>
          <el-input v-model="name" placeholder="请输入内容"></el-input>
        </el-col> -->
        <!-- <el-col :span="6">
           <el-button type="primary" @click="searchProject">搜索</el-button>
        </el-col> -->
      </el-row>
      <!-- <el-button type="primary" @click="openAddDialog">添加项目</el-button> -->
    </div>
    <div class="main">
      <el-table :data="tableData" max-height="770" style="width: 100%" border stripe>
        <el-table-column prop="name" label="项目名称"> </el-table-column>
        <el-table-column prop="corporations" label="所属公司">
        </el-table-column>
        <el-table-column prop="details" label="项目详情" show-overflow-tooltip> </el-table-column>
        <el-table-column prop="site" label="项目地址"> </el-table-column>
        <el-table-column prop="engineering" label="工程性质"> </el-table-column>
        <el-table-column prop="angineering" label="工程阶段"> </el-table-column>
        <el-table-column label="工程状态">
          <template slot-scope="scope">
            <span>{{ scope.row.startworks == '0' ? '停工' : '作业中' }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="expiration_project" label="服务到期时间"> </el-table-column>
        <el-table-column fixed="right" label="操作" :width="isxmtj ? '470' : '130'" v-if="isxmtj">
          <template slot-scope="scope">
            <div v-if="scope.row.data_pro_type">
              <el-button type="primary" @click="openEditDialog(scope.row)" size="small ">编辑</el-button>
              <el-button type="primary" @click="openAddUserDialog(scope.row)" v-if="isxmtj"
                size="small ">添加人员账号</el-button>
              <!-- <el-button type="primary" @click="openConstructionDialog(scope.row)" v-if="isxmtj"
                size="small ">施工要求</el-button> -->
              <el-button type="primary" @click="chongzhi(scope.row)" size="small" v-if="xmtj">充值</el-button>
              <el-button type="primary" @click="xiugaijine(scope.row)" size="small"
                v-if="isdealer == 'true'">修改金额</el-button>
              <el-button type="primary" @click="expirationTimes(scope.row)" size="small"
                v-if="isdealer == 'true'">修改到期时间</el-button>
            </div>
            <div v-else>
              <el-button type="danger" @click="chongzhi(scope.row)" size="small">请充值</el-button>
              <el-button type="primary" @click="xiugaijine(scope.row)" size="small"
                v-if="isdealer == 'true'">修改金额</el-button>
              <el-button type="primary" @click="expirationTimes(scope.row)" size="small"
                v-if="isdealer == 'true'">修改到期时间</el-button>
            </div>

            <!-- <el-button type="primary" @click="add(scope.row.id)" v-if="isxmtj">添加任务</el-button> -->
            <!-- <el-button type="danger" @click="deleteProject(scope.row)">删除<el-button> -->
          </template>
        </el-table-column>
      </el-table>
    </div>
    <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="page"
      :page-sizes="[10, 20, 50]" :page-size="pageSize" layout="total, sizes, prev, pager, next, jumper" :total="total">
    </el-pagination>
    <!-- ///////////////////////////////////////////////////////////////// -->
    <!-- 添加 -->
    <el-dialog title="添加" :visible.sync="dialogFormVisible" width="30%">
      <el-form>
        <el-form-item label="任务名" :label-width="formLabelWidth">
          <el-input autocomplete="off" v-model="info.taskname"></el-input>
        </el-form-item>
        <el-form-item label="任务类型" :label-width="formLabelWidth">
          <el-select v-model="info.tasktype" placeholder="请选择" style="width: 415px">
            <el-option v-for="item in options1" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="任务风险等级" :label-width="formLabelWidth">
          <el-select v-model="info.riskgrade" placeholder="请选择" style="width: 415px">
            <el-option v-for="item in options2" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="任务详情" :label-width="formLabelWidth">
          <el-input autocomplete="off" v-model="info.details"></el-input>
        </el-form-item>
        <el-form-item label="任务执行标准" :label-width="formLabelWidth">
          <el-input autocomplete="off" v-model="info.standard"></el-input>
        </el-form-item>
        <el-form-item label="责任人" :label-width="formLabelWidth">
          <el-select v-model="info.uid" placeholder="请选择" style="width: 415px">
            <el-option v-for="item in options3" :key="item.uid" :label="item.name" :value="item.uid">
            </el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible = false">取 消</el-button>
        <el-button type="primary" @click="determine">确 定</el-button>
      </div>
    </el-dialog>

    <!-- 修改项目对话框 -->
    <el-dialog title="修改项目信息" :visible.sync="editDialogVisible" width="40%">
      <el-form ref="editFormData" :model="editFormData" label-width="100px">
        <el-form-item label="项目名称" prop="name">
          <el-input v-model="editFormData.name"></el-input>
        </el-form-item>

        <el-form-item label="项目详情" prop="details">
          <el-input v-model="editFormData.details"></el-input>
        </el-form-item>
        <el-form-item label="项目地址" prop="site">
          <el-input v-model="editFormData.site"></el-input>
        </el-form-item>
        <el-form-item label="工程性质" prop="engineering">
          <el-input v-model="editFormData.engineering"></el-input>
        </el-form-item>
        <el-form-item label="开工时间" prop="create_time">
          <el-date-picker v-model="editFormData.create_time" value-format="yyyy-MM-dd HH:mm:ss" type="date"
            placeholder="选择日期">
          </el-date-picker>
        </el-form-item>
        <el-form-item label="拟竣工时间" prop="update_time">
          <el-date-picker v-model="editFormData.update_time" value-format="yyyy-MM-dd HH:mm:ss" type="date"
            placeholder="选择日期">
          </el-date-picker>
        </el-form-item>
        <el-form-item label="工程阶段" prop="angineering">
          <el-input v-model="editFormData.angineering"></el-input>
        </el-form-item>
        <el-form-item label="工程状态" prop="angineering">
          <!-- <el-input v-model="editFormData.angineering"></el-input> -->
          <el-select v-model="editFormData.startworks" placeholder="请选择">
            <el-option v-for="item in statusOptions" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="所在地" prop="detaileds">
          <el-cascader size="large" :options="options" v-model="editFormData.detaileds" clearable />
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="editDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="editProject">确 定</el-button>
      </span>
    </el-dialog>

    <!-- 添加人员对话框 -->
    <el-dialog title="添加人员" :visible.sync="adduserDialogVisible" width="40%" @close="closeAddDialog">
      <el-form ref="addFormData" :model="addFormData" label-width="80px" :rules="addFormDataRules">
        <el-form-item label="用户名" prop="name">
          <el-input v-model="addFormData.name"></el-input>
        </el-form-item>
        <el-form-item label="密码" prop="password">
          <el-input v-model="addFormData.password"></el-input>
        </el-form-item>
        <el-form-item label="手机号" prop="phone">
          <el-input v-model="addFormData.phone"></el-input>
        </el-form-item>
        <el-form-item label="用户等级" prop="rank">
          <el-select v-model="addFormData.rank" placeholder="请选择">
            <el-option v-for="item in rankOptions" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="职位" prop="position">
          <!-- <el-input v-model="addFormData.position"></el-input> -->
          <span v-if="addFormData.position != ''">{{ this.addFormData.position }}</span>
          <span v-if="addFormData.position != ''">
            <el-button type="text" @click="delete_name">重置</el-button>
          </span>
          <el-tree v-if="addFormData.position == ''" :data="data" @check-change='getCheckedNodes' show-checkbox
            check-strictly></el-tree>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="adduserDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="addUser">确 定</el-button>
      </span>
    </el-dialog>
    <!-- 添加施工要求对话框 -->
    <el-dialog title="提示" :visible.sync="constructionDialogVisible" width="30%">
      <el-form ref="constructionFormData" :model="constructionFormData" label-width="80px">
        <el-form-item label="施工部位">
          <el-input v-model="constructionFormData.construction_request"></el-input>
        </el-form-item>
        <el-form-item label="施工要求">
          <el-input v-model="constructionFormData.requirement"></el-input>
        </el-form-item>
        <el-form-item label="选择图片">
          <el-upload class="upload-demo" action="http://139.9.0.126:8088/api/document/crt/" :on-preview="handlePreview"
            :on-remove="handleRemove" :on-success="successHandle" :before-remove="beforeRemove" multiple :limit="3"
            :headers="token" :on-exceed="handleExceed" :file-list="fileList">
            <el-button size="small" type="primary">点击上传</el-button>
            <div slot="tip" class="el-upload__tip">
              只能上传jpg/png文件，且不超过500kb
            </div>
          </el-upload>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="constructionDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="uploadConstruction">确 定</el-button>
      </span>
    </el-dialog>
    <el-dialog title="充值" :visible.sync="dialogVisible" width="20%" :close-on-click-modal="false"
      :close-on-press-escape="false">
      <div style="font-size: 18px; font-weight:bold; margin: 0 0 10px 0;">
        <div style="margin: 10px 0;">
          充值项目:<span>{{ code_row.name }}</span>
        </div>
        <div style="margin: 10px 0;">
          充值金额:<span style="color: #ff0101;">{{ code_row.Renewal }}</span>元 为期续费一年云服务
        </div>
      </div>
      <div style="margin-left:30px ;">
        <el-radio-group v-model="radio3" size="small" @input="bianhuan">
          <el-radio label="1" border>微信支付</el-radio>
          <el-radio label="2" border>支付宝支付</el-radio>
        </el-radio-group>
      </div>
      <div v-if="radio3 == '1'" style="margin:10px auto ;width:150px;height:150px">
        <img :src="code_url" alt="二维码" style="width:150px;height:150px">
      </div>
      <div v-if="radio3 == '2'" style="margin:10px auto ;width:150px;height:150px">
        <iframe :src="code_url" scrolling="no" frameborder="0" style="width:100%;height:100%"></iframe>
      </div>

      <div v-if="radio3 != ''" style="text-align: center;color: #ff0101;font-size: 18px; font-weight:bold">
        购买前确认好虚拟商品,售出后无法退换
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisibles">取 消</el-button>
        <el-button type="primary" @click="dialogVisibles">确 定</el-button>
      </span>
    </el-dialog>
    <el-dialog title="修改年费金额" :visible.sync="dialogVisibles1" width="30%">
      <el-form :inline="true" :model="formInline" class="demo-form-inline">
        <el-form-item label="修改年费金额(元):">
          <el-input v-model="formInline.user" placeholder="充值金额"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisibles1 = false">取 消</el-button>
        <el-button type="primary" @click="xiguainianfei">确 定</el-button>
      </span>
    </el-dialog>
    <el-dialog title="修改到期时间" :visible.sync="dialogVisibles2" width="20%">
      <div>
        <el-date-picker v-model="value1" type="date" placeholder="选择日期" value-format="yyyy-MM-dd">
        </el-date-picker>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisibles2 = false">取 消</el-button>
        <el-button type="primary" @click="expirationTime">确 定</el-button>
      </span>
    </el-dialog>

  </div>
</template>

<script>
import {
  getProjectInfo,
  editProjectInfo,
  deleteProjectInfo,
  addUserInfo,
  addConstruction,
  addprojectionfo,
  addprojectionfo1,
  show_Department,
} from "../../../request/project";
import {
  addProjectInfo,
  addProjectInfo1,
  zhifu_weixin,
  zhifu_zhifubao,
  rechargeOrNot,
  xiguainianfei,
  expirationTime
} from "../../../request/project";
import { regionData, CodeToText, TextToCode } from "element-china-area-data";

export default {
  data() {
    return {
      dialogVisibles2: false,
      value1: '',
      formInline: {
        user: '',
      },
      dialogVisibles1: false,
      Order_number: '',
      amount_paid: '',
      radio3: '',
      code_url: '',
      dialogVisible: false,
      isxmtj: true,
      data: [],
      addDialogVisible: false, //控制添加项目对话框的显示与隐藏
      xmtj: false,
      addProjectFormData: {
        name: "",
        corporation: "",
        details: "",
        site: "",
        create_time: "",
        update_time: "",
        engineering: "",
        angineering: "",
        detaileds: "",
      }, //添加项目信息表单
      addProjectFormDataRules: {
        name: [{ required: true, message: "请输入名称", trigger: "change" }],
        details: [{ required: true, message: "请输入名称", trigger: "change" }],
        site: [{ required: true, message: "请输入名称", trigger: "change" }],
        engineering: [
          { required: true, message: "请输入名称", trigger: "change" },
        ],
        angineering: [
          { required: true, message: "请输入名称", trigger: "change" },
        ],
        detailed: [
          { required: true, message: "请输入名称", trigger: "change" },
        ],
      },
      projectOptions: [
        {
          label: "基础施工阶段",
          value: "基础施工阶段",
        },
        {
          label: "主体施工阶段",
          value: "主体施工阶段",
        },
        {
          label: "装饰装修阶段",
          value: "装饰装修阶段",
        },
      ], //项目阶段下拉框
      editDialogVisible: false, //控制修改项目对话框的显示与隐藏

      editFormData: {
        name: "",
        //   corporation: "",
        details: "",
        site: "",
        engineering: "",
        create_time: "",
        update_time: "",
        angineering: "",
        detaileds: "",
      }, //添加项目信息表单
      name: "", //项目名称
      corporation: "", //所属公司
      enterpriseOptions: [], //所属公司下拉框
      tableData: [], //展示项目信息表格
      tableData1: [], //展示项目信息表格
      adduserDialogVisible: false,
      addFormDataRules: {
        name: [{ required: true, message: "请输入名称", trigger: "change" }],
        password: [
          { required: true, message: "请输入密码", trigger: "change" },
        ],
        phone: [{ required: true, message: "请输入手机号", trigger: "change" }],
        position: [
          { required: true, message: "请输入职位", trigger: "change" },
        ],
      },
      addFormData: {
        name: "",
        password: "",
        phone: "",
        rank: "",
        position: "",
        corporation: "",
        company: "",
      },
      rankOptions: [
        {
          value: 0,
          label: "系统管理员",
        },
        {
          value: 1,
          label: "公司管理员",
        },
        {
          value: 2,
          label: "项目管理员",
        },
        {
          value: 3,
          label: "普通员工",
        },
      ],
      options: regionData, // 省市区三级联动
      selectedOptions: [],
      statusOptions: [
        {
          value: "0",
          label: "停工",
        },
        {
          value: "1",
          label: "作业中",
        },
      ],
      constructionDialogVisible: false, //添加施工要求对话框显示与隐藏
      fileList: [],
      constructionFormData: {
        project_id: "",
        construction_request: "", //施工地点
        requirement: "", // 施工要求
        project_img: [], // 施工图片
      },
      token: {
        Authorization: localStorage.getItem("TOKEN"),
      },
      dialogFormVisible: false, //添加弹框
      formLabelWidth: "120px",
      options1: [
        {
          label: "定期检查",
          value: "定期检查",
        },
        {
          label: "专项检查",
          value: "专项检查",
        },
        {
          label: "季节性检查",
          value: "季节性检查",
        },
        {
          label: "其他检查",
          value: "其他检查",
        },
      ],
      options2: [
        {
          label: "低风险",
          value: "低风险",
        },
        {
          label: "一般风险",
          value: "一般风险",
        },
        {
          label: "较大风险",
          value: "较大风险",
        },
        {
          label: "重大风险",
          value: "重大风险",
        },
      ],
      options3: [], //责任人
      info: {
        taskname: "",//任务
        tasktype: "", //任务类型下拉框
        riskgrade: "", //风险等级下拉框
        details: "",//任务详情
        standard: "",//任务执行标准
        uid: "", //责任人id
        corporation: 0,//当前存放项目的id
      },
      flag: true,// 系统管理员 政府账号 true   项目管理员  普通用户  false
      total: 10, // 表格分页总数
      page: 1,
      pageSize: 10,
      pjtid: '',
      code_row: {},
      isdealer: '',
      rank:'',
    };
  },

  watch: {
    $route() {
      this.pjtid = this.$route.query.id
    },
    pjtid() {
      this.current = 1;
      this.getProject(); //获取所有项目
      if (localStorage.getItem('rank') == 1) {
        this.xmtj = true
      } else {
        this.xmtj = false
      }
      if (localStorage.getItem('rank') == 4) {
        this.isxmtj = false
      } else {

      }
    },
  },
  created() { },
  mounted() {
    this.rank = localStorage.getItem('rank')

    // this.getEnterprise();
    this.isdealer = localStorage.getItem('isdealer')
    this.getProject(); //获取所有项目
    if (localStorage.getItem('rank') == 1) {
      this.xmtj = true
    } else {
      this.xmtj = false
    }
    if (localStorage.getItem('rank') == 4) {
      this.isxmtj = false
    } else {

    }

  },
  methods: {
    expirationTimes(row) {
      this.dialogVisibles2 = true
      this.value1 = row.expiration_project
      this.code_row = row

    },
    async expirationTime() {
      let msg = {
        unique_identification: localStorage.getItem('uid'),
        pro_id: this.code_row.id,
        expiration_project: this.value1
      }
      const res = await expirationTime(msg)
      if (res.data.code == 200) {
        this.$message({
          message: '修改成功',
          type: 'success'
        });
        this.dialogVisibles2 = false
        this.getProject();

      }else{
        this.$message.error(res.data.msg);
      }
    },
    async xiguainianfei() {
      let msg = {
        unique_identification: localStorage.getItem('uid'),
        pro_id: this.code_row.id,
        Renewal: this.formInline.user

      }
      const res = await xiguainianfei(msg)
      if (res.data.code == 200) {
        this.$message({
          message: '修改成功',
          type: 'success'
        });
        this.dialogVisibles1 = false
        this.formInline.user = ''

      } else {
        this.$message({
          message: res.data.msg,
          type: 'error'
        });
      }
    },
    xiugaijine(row) {
      this.dialogVisibles1 = true
      this.formInline.user = row.Renewal
      this.code_row = row

    },
    async rechargeOrNot() {
      let msg = {
        Order_number: this.Order_number
      }
      const res = await rechargeOrNot(msg)
      console.log(res, '1111');
      if (res.data.code == 200) {
        this.$message({
          message: res.data.msg,
          type: 'success'
        });
      } else {
        this.$message({
          message: res.data.msg,
          type: 'error'
        });
      }
      this.dialogVisible = false
      this.radio3 = ''
      this.code_url = ''
      this.getProject()
    },
    dialogVisibles() {
      if (this.radio3 != '') {
        this.rechargeOrNot()
      } else {
        this.$message({
          message: '用户取消支付',
          type: 'warning'
        });
        this.dialogVisible = false
        this.radio3 = ''
        this.code_url = ''
      }
    },
    bianhuan() {
      console.log(this.radio3);
      if (this.radio3 == '1') {
        this.zhifu_weixin()
      } else if (this.radio3 == '2') {

        this.zhifu_zhifubao()
      }

    },
    chongzhi(row) {
      this.dialogVisible = true
      this.code_row = row
      // this.zhifu_weixin()

    },

    async zhifu_weixin() {
      let msg = {
        operator: localStorage.getItem('uid'),
        topup_item: this.code_row.id,
        affiliated_company: this.code_row.corporation,
        remark: '',
        recharge_information: '建议购买前确认好虚拟商品,售出后无法退换'
      }
      const res = await zhifu_weixin(msg)
      this.code_url = res.data.data
      this.amount_paid = res.data.amount_paid
      this.Order_number = res.data.Order_number

      console.log(res);
    },
    async zhifu_zhifubao() {
      let msg = {
        operator: localStorage.getItem('uid'),
        topup_item: this.code_row.id,
        affiliated_company: this.code_row.corporation,
        remark: '',
        recharge_information: '建议购买前确认好虚拟商品,售出后无法退换'
      }
      const res = await zhifu_zhifubao(msg)
      this.code_url = res.data.data
      this.amount_paid = res.data.amount_paid
      this.Order_number = res.data.Order_number

    },

    getCheckedNodes(leafOnly) {
      console.log(leafOnly)
      this.addFormData.position = leafOnly.label
    },
    delete_name() {
      this.addFormData.position = ''
    },
    // 获取所有公司
    async getcompany(id) {
      var msg = {
        pro_id: id
      }
      const { data: res } = await show_Department(msg);
      console.log(res)
      this.data = res.data
    },
    openAddProjectDialog() {
      this.addDialogVisible = true;
      this.addProjectFormData.corporation = localStorage.getItem('companys');
      this.addProject1()
    },
    // 添加项目
    async addProject() {
      const res = await addProjectInfo(this.addProjectFormData);
      // console.log(res);
      if (res.status == 200) {
        this.getProject();
        this.addDialogVisible = false;
        this.$message({
          message: "添加成功",
          type: "success",
        });
      }
    },
    //获取项目审核列表
    async addProject1() {
      let msg = {
        unique_identification: localStorage.getItem('uid')
      }
      const res = await addProjectInfo1(msg);
      this.tableData1 = res.data.data
    },

    // 关闭添加项目对话框,清空表单
    closeAddDialog() {
      this.$refs.addProjectFormData.resetFields();
    },
    // 切换每页显示几条数据
    handleSizeChange(val) {
      this.pageSize = val;
      // console.log(this.pageSize);
      this.getProject();
    },
    // 切换页码
    handleCurrentChange(val) {
      this.page = val;
      this.getProject();
    },
    // 打开添加施工要求
    openConstructionDialog(row) {
      // console.log(row);
      this.constructionFormData.project_id = row.id;
      this.constructionDialogVisible = true;

    },
    // 添加施工要求
    async uploadConstruction() {
      const res = await addConstruction(this.constructionFormData);
      this.constructionDialogVisible = false;
    },
    handleRemove(file, fileList) {

    },
    handlePreview(file) {

    },
    handleExceed(files, fileList) {
      this.$message.warning(
        `当前限制选择 3 个文件，本次选择了 ${files.length} 个文件，共选择了 ${files.length + fileList.length
        } 个文件`
      );
    },
    beforeRemove(file, fileList) {
      return this.$confirm(`确定移除 ${file.name}？`);
    },
    successHandle(res) {
      // console.log(res.res);
      this.constructionFormData.project_img.push(res.res);
    },
    // 打开添加用户对话框
    openAddUserDialog(row) {
      this.getcompany(row.id)
      this.adduserDialogVisible = true;
      this.addFormData.corporation = row.id;
      this.addFormData.company = row.corporation;
      // console.log(row);
      console.log("--------");
      if (localStorage.getItem("rank") == 0) {
        this.rankOptions = [
          {
            value: 0,
            label: "系统管理员",
          },
          {
            value: 1,
            label: "公司管理员",
          },
          {
            value: 2,
            label: "项目管理员",
          },
          {
            value: 3,
            label: "普通员工",
          },
        ];
      } else if (localStorage.getItem("rank") == 1) {
        this.rankOptions = [
          {
            value: 2,
            label: "项目管理员",
          },
          {
            value: 3,
            label: "普通员工",
          },
        ];
      } else if (localStorage.getItem("rank") == 2) {
        this.rankOptions = [
          {
            value: 3,
            label: "普通员工",
          },
        ];
      } else {
        this.rankOptions = [];
      }
    },
    // 添加用户
    async addUser() {
      const res = await addUserInfo(this.addFormData);
      // console.log(res.data.code);

      if (res.data.code == 200) {
        // this.$message({

        //   message: "添加失败",
        //   type: "error",
        // });
        this.adduserDialogVisible = false;

        this.$message({
          message: "添加人员成功",
          type: "success",
        });
        return;
      }
      this.$message({
        message: res.data.msg,
        type: "error",
      });
    },

    // 添加用户对话框关闭清除表单
    closeAddDialog() {
      this.$refs.addFormData.resetFields();
    },

    //   获取所有的项目信息
    async getProject() {
      this.flag =
        localStorage.getItem("rank") == 0 || localStorage.getItem("rank") == 1
          ? true
          : false;
      let msg = {
        //   name:this.name,  pro  1 项目   0 公司
        // corporation:this.corporation
        unique_identification: localStorage.getItem("uid"),
        page: this.page,
        name: "",
        phase: '',
        page: this.page,
        size: this.pageSize
      };
      if (localStorage.getItem('isdealer') == 'true') {
        if (localStorage.getItem('type') == 'pro') {
          msg.pro_id = localStorage.getItem('project')
        } else if (localStorage.getItem('type') == 'firm') {
          msg.firm_id = localStorage.getItem('project')
        }
      }
      const res = await getProjectInfo(msg);

      console.log(res);
      this.tableData = res.data.data;
      this.total = res.data.count;
      // for (let i = 0; i < this.tableData.length; i++) {
      //   this.tableData[i].startwork =
      //     this.tableData[i].startworks == 0 ? "停工" : "作业中";
      //   let str = this.tableData[i].detaileds[2];
      //   str = str.substring(1, 7);
      //   this.tableData[i].address = CodeToText[str];
      // }
      // console.log(this.tableData);
    },
    // 搜索项目
    searchProject() {
      this.getProject();
    },
    //   打开修改项目对话框
    openEditDialog(row) {
      this.$nextTick(() => {
        this.editFormData = JSON.parse(JSON.stringify(row));
        this.editFormData.detaileds[1] =
          this.editFormData.detaileds[1].substring(1, 7);
        this.editFormData.detaileds[2] =
          this.editFormData.detaileds[2].substring(1, 7);

        console.log(this.editFormData);
      });
      this.editDialogVisible = true;
    },
    // // 获取所有企业/公司
    // async getEnterprise() {

    //   const { data: res } = await getFirm();
    //   //   console.log(res);
    //   for (let i = 0; i < res.length; i++) {
    //     let obj = {};
    //     obj.value = res[i].id;
    //     obj.label = res[i].name;
    //     this.enterpriseOptions.push(obj);
    //   }
    //   this.corporation = this.enterpriseOptions[0].value;
    //   this.getProject();
    // },
    // 修改项目
    async editProject() {
      const res = await editProjectInfo(this.editFormData);
      console.log(res);
      if (res.status == 200) {
        this.editDialogVisible = false;
        this.getProject();
        this.$message({
          message: "修改成功",
          type: "success",
        });
      }
    },
    // 删除项目
    deleteProject(row) {
      this.$confirm("是否删除此项目?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(async () => {
          let msg = {
            id: row.id,
          };
          const res = await deleteProjectInfo(msg);
          console.log(res);
          if (res.data.code == 200) {
            this.getProject();
            this.$message({
              type: "success",
              message: "删除成功!",
            });
          }
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    //添加
    async add(id) {
      this.info.corporation = id
      this.dialogFormVisible = true;
      let msg = {
        corporation: id,
      };
      const res = await addprojectionfo(msg);
      // console.log(res,125521)
      this.options3 = res.data;
      // console.log(this.options3, 120);
    },
    //确定
    async determine() {
      this.dialogFormVisible = false;
      let msg = {
        taskname: this.info.taskname,
        tasktype: this.info.tasktype,
        riskgrade: this.info.riskgrade,
        details: this.info.details,
        standard: this.info.standard,
        corporation: this.info.corporation,
        uid: this.info.uid
      }
      const res = await addprojectionfo1(msg);
      console.log(res, 159)

      // console.log(this.info,1230)
    }
  },
};
</script>
<style lang="less" scoped>
.search .el-input {
  width: 50%;

}

.search .el-select {
  width: 80%;

}

.main {
  padding: 20px;
  // color: #ff0101;
}
</style>